import React from "react";
import "./contact-us.css";

const ContactUs = ({ closeModal }) => (
  <div className="help-shell">
    <div className="close-help-cross-aligner">
      <button onClick={closeModal} className="close-help-window">
        <span className="close-help-cross__btn">x</span>
      </button>
    </div>
    <div className="modal-text">
      <span>Contact us at</span>
      <a className="support-link" href="mailto:support@purity.no">
        support@purity.no
      </a>
    </div>
  </div>
);

export default ContactUs;
